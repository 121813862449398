import firebase from 'firebase'

//Cabeçalho
export const colsEscrita = [{
  title: 'Empresa:',
  sorter:true,
  dataIndex: 'empresa',
  key: 'empresa',
},
{
  title: 'Regime:',
  sorter:true,
  dataIndex: 'regime',
  key: 'regime',
},
{
  title: 'Cnpj:',
  dataIndex: 'cnpj',
  key: 'cnpj',
}];

//getDados
const rootRef = firebase.database().ref();
export function apiRespEscrita(uid){
  let promise = new Promise((resolve,reject)=>{
    rootRef.child('assessorpj/'+uid).once('value').then(snap=>{
      let data=[];
      data=[];
      Object.keys(snap.val()).map((key, index) => {
        rootRef.child('empresas/'+key).once('value').then(emp=>{
          data[emp.key]=emp.val();
        });
      });
      resolve(data);
    })
  })
  return promise;
}
