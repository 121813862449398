const DOCUMENT = 'COVID_';

const actions = {
  LOAD_FROM_DATABASE: DOCUMENT + 'LOAD_FROM_DATABASE',
  LOAD_FROM_DATABASE_SUCCESS: DOCUMENT + 'LOAD_FROM_DATABASE_SUCCESS',
  LOAD_FROM_DATABASE_ERROR: DOCUMENT + 'LOAD_FROM_DATABASE_ERROR',

  SAVE_INTO_DATABASE: DOCUMENT + 'SAVE_INTO_DATABASE',
  SAVE_INTO_DATABASE_ERROR: DOCUMENT + 'SAVE_INTO_DATABASE_ERROR',

  RESET_DATABASE_DOCUMENTS: DOCUMENT + 'RESET_DATABASE_DOCUMENTS',
  RESET_DATABASE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_DATABASE_DOCUMENTS_ERROR',

  TOGGLE_DATABASE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_DATABASE_HANDLE_MODAL',
  DATABASE_UPDATE: DOCUMENT + 'DATABASE_UPDATE',

  loadFromDataBase: () => {
    return { type: actions.LOAD_FROM_DATABASE };
  },

  loadFromDataBaseSuccess: data => ({
    type: actions.LOAD_FROM_DATABASE_SUCCESS,
    payload: { data },
  }),

  loadFromDataBaseError: error => ({
    type: actions.LOAD_FROM_DataBase_ERROR,
    payload: { error },
  }),

  saveIntoDataBase: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_DATABASE,
    payload: { data, actionName },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_DATABASE_HANDLE_MODAL,
    payload: { data },
  }),

  update: data => ({
    type: actions.DATABASE_UPDATE,
    payload: { data },
  }),

  saveIntoDataBaseError: error => ({
    type: actions.SAVE_INTO_DATABASE_ERROR,
    payload: { error },
  }),

  resetDataBaseDocuments: () => ({
    type: actions.RESET_DATABASE_DOCUMENTS,
  }),

  resetDataBaseDocumentsError: error => ({
    type: actions.RESET_DATABASE_DOCUMENTS_ERROR,
    payload: { error },
  }),
};
export default actions;
