import asyncComponent from '../helpers/AsyncFunc';

const routes = [
  {
    path: 'githubSearch',
    component: asyncComponent(() => import('./containers/GithubSearch'))
  },
  {
    path: 'blank_page',
    component: asyncComponent(() => import('./containers/blankPage'))
  },
  {
    path: 'irpf',
    component: asyncComponent(() => import('./containers/irpf'))
  },
  {
    path: 'videos',
    component: asyncComponent(() => import('../containers/YoutubeSearch/index'))
  },
  {
    path: 'enviar_arq',
    component: asyncComponent(() => import('@iso/containers/UploadArq/index'))
  },
  /*{
    path: 'inicio',
    component: asyncComponent(() => import('./containers/blankPage'))
  },*/
];
export default routes;
