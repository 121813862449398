import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import FirebaseHelper from '../../helpers/firebase';

const {rsf} = FirebaseHelper;

const COLLECTION_NAME = 'covid'; // change your collection

function* loadFromDataBase() {
  try {
    const data = yield call(rsf.database.read, COLLECTION_NAME);
    yield put(actions.loadFromDataBaseSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromDataBaseError(error));
  }
}

function* storeIntoDataBase({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(rsf.database.delete, `${COLLECTION_NAME}/${data.key}`);
        break;
      case 'update':
        yield call(rsf.database.update,  `${COLLECTION_NAME}/${data.key}`, data);
        break;
      default:
        yield call(rsf.database.create, COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_DATABASE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoDataBaseError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_DATABASE, loadFromDataBase),
    takeEvery(actions.SAVE_INTO_DATABASE, storeIntoDataBase),
  ]);
}
