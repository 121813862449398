import React from 'react';
import { Route, Redirect, Router,Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';
import Layout from './pages/components/Layout/';

  const RestrictedRoute= ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />

  
);

/**
 * 
 * Rotas públicas, verifica depois do .com.br
 */
const PublicRoutes = ({ history, isLoggedIn }) => {
  const Irpf = React.lazy(() => import('./pages/pages/IRPF/index'));
  const Covid19 = React.lazy(() => import('./pages/pages/Covid19/index'));
  const Mei = React.lazy(() => import('./pages/pages/Mei/index'));
  const Icmsst = React.lazy(() => import('./pages/pages/ICMSST/index'));
  const Sistemast = React.lazy(() => import('./pages/pages/SistemaSt/index'));
  return (
    <React.Fragment> 
    <ConnectedRouter history={history}>
      <Router history={history}>
        <Route
          exact
          path={'/index'}
          component={asyncComponent(() => import('./customApp/containers/Pages/signin'))}
        />
        <Route
          exact
          path={'/404'}
          component={asyncComponent(() => import('./containers/Page/404'))}
        />
        <Route
          exact
          path={'/500'}
          component={asyncComponent(() => import('./containers/Page/500'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./customApp/containers/Pages/signin'))}
        />
        <Route
          exact
          path={'/signup'}
          component={asyncComponent(() => import('./customApp/containers/Pages/signup'))}
        />
        <Route
          exact
          path={'/forgotpassword'}
          component={asyncComponent(() =>
            import('./containers/Page/forgotPassword')
          )}
        />
        <Route
          exact
          path={'/resetpassword'}
          component={asyncComponent(() =>
            import('./containers/Page/resetPassword')
          )}
        />

        <React.Suspense fallback={<div></div>}>
          <Route
            exact
            path={'/irpf'}
            component={withLayout(Irpf)}
          />
        </React.Suspense>

        <React.Suspense fallback={<div></div>}>
          <Route
            exact
            path={'/mei'}
            component={withLayout(Mei)}
          />
        </React.Suspense>


      <React.Suspense fallback={<div></div>}>
          <Route
            exact
            path={'/'}
            component={withLayout(Irpf)}
          />
      </React.Suspense>

      <React.Suspense fallback={<div></div>}>
          <Route
            exact
            path={'/covid19'}
            //component={withLayout(Covid19)}
            component={withLayout(Covid19)}
          />
       </React.Suspense>

       <React.Suspense fallback={<div></div>}>
          <Route
            exact
            path={'/substituicaotributaria'}
            //component={withLayout(Covid19)}
            component={withLayout(Icmsst)}
          />
       </React.Suspense>

       <React.Suspense fallback={<div></div>}>
          <Route
            exact
            path={'/sistemast'}
            //component={withLayout(Covid19)}
            component={withLayout(Sistemast)}
          />
       </React.Suspense>

        <RestrictedRoute
        path="/dashboard"
        component={App}
        isLoggedIn={isLoggedIn}
      />
      <RestrictedRoute
        path="/app"
        component={App}
        isLoggedIn={isLoggedIn}
      />

      <RestrictedRoute
        path="/esp"
        component={App}
        isLoggedIn={isLoggedIn}
      />

        {/*<Redirect from="*" to="/"></Redirect>*/
      }

      </Router>
    </ConnectedRouter>
    </React.Fragment>
  );
};

function withLayout (WrappedComponent) {
  return class extends React.Component {  
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    }
  };
}
export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);