import Usuario from "../../customApp/model/Usuario";
export const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  EMAIL_VERIFICATION_SAGA: 'EMAIL_VERIFICATION_SAGA',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  EMAIL_RESET: 'EMAIL_RESET',
  EMAIL_RESET_OK: 'EMAIL_RESET_OK',
  EMAIL_RESET_ER: 'EMAIL_RESET_ER',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  GET_USER: 'GET_USER',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  CHECK: 'CHECK',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  getUser:(user) => ({
    type: actions.GET_USER,
    user: user 
  })
};

export const emailVerificationSaga = (payload) => ({
  type: actions.EMAIL_VERIFICATION_SAGA,
  payload:payload
});

export const emailReset = (email) => ({
  type: actions.EMAIL_RESET,
  email:email
});

export const createUser = (email,password,name) => ({
  type: actions.CREATE_USER,
  email:email,
  password:password,
  name:name,
});

export const getUser = () => {
  return dispatch => {
      dispatch(Usuario.getUser())
  }
}
export default actions;
