import actions from "./actions";

const initState = { loading:true, dataEscrita:[], loadErro:false, loadSuccess:false, msg:""};

export default function assessorReducer(state = initState, action) {
  switch (action.type) {
    case actions.ASSESSOR_PJ_LOAD:
      return {
        ...state,
        loading: true,
      };
    case actions.ASSESSOR_PJ_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        dataEscrita: Object.values(action.payload.data),
        loadSuccess: true,
      };
   case actions.ASSESSOR_PJ_RESET:
      return initState;
   case actions.ASSESSOR_PJ_LOAD_ERROR:
        return {...state, 
          loadErro:true,
          msg:action.payload.msg
        };  
    default:
      return {...state};
  }
}
