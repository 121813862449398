import actions from "./actions";

const initState = { isLoggedIn:false, idToken: null, uid:null, displayName:null, photoURL:null,email:null, emailVerified:null,sendConfirmation:true,emailReset:false,createUser:false,msg:""};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        isLoggedIn: true,
        uid: action.payload.uid,
        displayName: action.payload.displayName,
        photoURL: action.payload.photoURL,
        email: action.payload.email,
        emailVerified: action.payload.emailVerified,
        idToken: action.payload.ra
      };
    case actions.LOGOUT:
      return initState;
   case actions.EMAIL_VERIFICATION_SAGA:
        return {...state, sendConfirmation:true};  
   case actions.EMAIL_RESET:
          return {...state};
   case actions.EMAIL_RESET_OK:
          return {...state, emailReset:true};            
    case actions.GET_USER:
        return [...state, action.user];
    case actions.CREATE_USER_SUCCESS:
          return {...state, createUser:true, email: action.email,name: action.name};
    case actions.CREATE_USER_ERROR:
            return {...state, createUserError:true, msg: action.payload.message};      
      case actions.CHECK_AUTHORIZATION:
        return {
          ...state
        }
       default:
      return {...state};
  }
}
