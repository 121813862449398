const DOCUMENT = 'UPLOAD_SITE_';

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',

  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  LOAD_FROM_FIRESTORE_GERENCIA: DOCUMENT + 'LOAD_FROM_FIRESTORE_GERENCIA',
  LOAD_FROM_FIRESTORE_GERENCIA_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_GERENCIA_SUCCESS',
  LOAD_FROM_FIRESTORE_GERENCIA_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_GERENCIA_ERROR',

  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  RESET_FIRESTORE_DOCUMENTS: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS',
  RESET_FIRESTORE_DOCUMENTS_ERROR: DOCUMENT + 'RESET_FIRESTORE_DOCUMENTS_ERROR',

  TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
  FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',
  FIRESTORE_UPDATE_EDITOR: DOCUMENT + 'FIRESTORE_UPDATE_EDITOR',

  DOWNLOAD: DOCUMENT + 'DOWNLOAD',

  LOAD_ANEXOS: DOCUMENT + 'LOAD_ANEXOS',
  LOAD_ANEXOS_SUCCESS: DOCUMENT + 'LOAD_ANEXOS_SUCCESS',
  LOAD_ANEXOS_ERROR: DOCUMENT + 'LOAD_ANEXOS_ERROR',

  DELETE_ANEXO: DOCUMENT + 'DELETE_ANEXO',

  SHOW_MENSAGEM: DOCUMENT + 'SHOW_MENSAGEM',

  loadFromFireStore: (uid) => {
    return { type: actions.LOAD_FROM_FIRESTORE,
          payload: {uid}};
  },

  loadFromFireStoreGerencia: (gerencia) => {
    return { type: actions.LOAD_FROM_FIRESTORE_GERENCIA,
          payload: {gerencia}};
  },

  loadFromFireStoreSuccess: (lidos, nao_lidos) => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { lidos, nao_lidos },
  }),

  loadFromFireStoreGerenciaSuccess: (lidos_gerencia, nao_lidos_gerencia) => ({
    type: actions.LOAD_FROM_FIRESTORE_GERENCIA_SUCCESS,
    payload: { lidos_gerencia, nao_lidos_gerencia },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  loadFromFireStoreGerenciaError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_GERENCIA_ERROR,
    payload: { error },
  }),

  loadAnexos: (uid, key) => {
    return { type: actions.LOAD_ANEXOS,
          payload: {uid, key}};
  },

  deleteAnexo: (path, keyAnexo) => {
    return { type: actions.DELETE_ANEXO,
          payload: {path, keyAnexo}};
  },

  loadAnexosSuccess: data => ({
    type: actions.LOAD_ANEXOS_SUCCESS,
    payload: { data },
  }),

  loadAnexosError: error => ({
    type: actions.LOAD_ANEXOS_ERROR,
    payload: { error },
  }),

  saveIntoFireStore: (data, actionName = 'insert', uid) => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName, uid },
  }),

  downloadFile: (data, fileName) => ({
    type: actions.DOWNLOAD,
    payload: { 
      "caminho": data,
      "fileName":fileName 
    },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  update: data => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  }),

  updateEditor: (data, campo) => ({
    type: actions.FIRESTORE_UPDATE_EDITOR,
    payload: { campo: data },
  }),

  saveIntoFireStoreError: error => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  resetFireStoreDocuments: () => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS,
  }),

  resetFireStoreDocumentsError: error => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
    payload: { error },
  }),

  showMensagem: (msg,msgType='default') => ({
    type: actions.SHOW_MENSAGEM,
    payload: { msg, msgType },
  }),
};
export default actions;
