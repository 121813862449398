const options = [
  {
    key: 'inicio',
    label: 'sidebar.home',
    leftIcon: 'ion-home',
    withoutDashboard: false,
  },
  {
    key: 'notificacao',
    label: 'sidebar.notificacao',
    leftIcon: 'ion-android-notifications',
    withoutDashboard: false,
  },
  
  {
    key: 'chat',
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
    withoutDashboard: true,
  },
  {
    key: 'videos',
    label: 'sidebar.videos',
    leftIcon: 'ion-social-youtube',
    withoutDashboard: false,
  },
  {
    key: 'documents',
    label: 'sidebar.documents',
    leftIcon: 'ion-document-text',
    children:[
      {
        key: 'irpf',
        label: 'sidebar.IRPF',
        leftIcon: 'ion-document-text',
        withoutDashboard: false, //Sai do dashboard
      },
      {
        key: 'guias',
        label: 'sidebar.guias',
        leftIcon: 'ion-document-text',
        withoutDashboard: false, //Sai do dashboard
      },
      {
        key: 'certidoes',
        label: 'sidebar.certidoes',
        leftIcon: 'ion-document-text',
        withoutDashboard: false, //Sai do dashboard
      },
      {
        key: 'contratos',
        label: 'sidebar.contratos',
        leftIcon: 'ion-document-text',
        withoutDashboard: false, //Sai do dashboard
      },
      
  ]
  },
  {
    key: 'financas',
    label: 'sidebar.financeiro',
    leftIcon: 'ion-cash',
    withoutDashboard: false,
    
  },
  {
    key: 'enviar_arq',
    label: 'sidebar.enviar_arq',
    leftIcon: 'ion-upload',
    withoutDashboard: false,
    
  }
];
export default options;
