import firebase from 'firebase';
import 'firebase/firestore';

export function getUser(){
  firebase.auth().onAuthStateChanged(user=> {
    if (user) {
      //console.log(user.uid);
      this.database.collection('users').doc(user.uid).get().then(result=>{
         let r =  result.data();
         return r;
      });
    } else {
      // No user is signed in.
    }
  });
}

export function loadUser(){
  var promise = new Promise(function(resolve, reject) {
    let database = firebase.firestore();

    firebase.auth().onAuthStateChanged(user=> {
      if (user) {
        database.collection('users').doc(user.uid).get().then(result=>{
           let r =  result.data();
           r.checkEmail = user.emailVerified;
           resolve(r);
        });
      } else {
        reject("Houve um erro de autenticação");
      }
    });
  });
  return promise;
}
export default getUser;