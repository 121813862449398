import {loadUser as getUser} from "../../model/Users";

export const actions = {
    LOAD_USER: 'LOAD_USER',
    GIT_SUCCESS_RESULT: 'GIT_SUCCESS_RESULT',
    GIT_ERROR_RESULT: 'GIT_ERROR_RESULT',
    onPageChange: (searcText, page) => ({
      type: actions.GIT_SEARCH,
      payload: { searcText, page }
    }),
    gitSearchSuccess: (result, total_count, page) => ({
      type: actions.GIT_SUCCESS_RESULT,
      result,
      total_count,
      page
    }),
    gitSearchError: () => ({
      type: actions.GIT_ERROR_RESULT
    })
  };

  export const loadUser = ()=>{
    return dispatch=>{
          getUser().then( r=> {
            dispatch({
                type: actions.LOAD_USER,
                payload: r
            })
        })
    }
  }

  export default actions;
  