import firebase from 'firebase';
import 'firebase/firestore';
class Usuario{
    getUsers(callback){
      let database = firebase.firestore();
        firebase.auth().onAuthStateChanged(user=> {
            if (user) {
              //console.log(user.uid);
              database.collection('users').doc(user.uid).get().then(result=>{
                 let r =  result.data();
                 callback(r);
              });
            } else {
              console.log('ghouve um erro');
              // No user is signed in.
            }
          });
    }

    loadUser(){
      var promise = new Promise(function(resolve, reject) {
        let database = firebase.firestore();

        firebase.auth().onAuthStateChanged(user=> {
          if (user) {
            database.collection('users').doc(user.uid).get().then(result=>{
               let r =  result.data();
               resolve(r);
            });
          } else {
            reject(Error("Houve um erro"));
          }
        });
      });
      return promise;
    }
}
export default new Usuario();

export const getAutentication = () =>{
  
    return firebase.auth().onAuthStateChanged(user=> {
      if (user) {
        user.name = 'Nilson Fabiano Junior';
        console.log(user);
        return user;
      }else{
        console.log('errado!!!')
      }
});
}