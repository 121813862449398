import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';

const {rsf} = FirebaseHelper;



export function* loadCredencial(payload) {
  const col = yield call(rsf.database.read, 'colaborador/'+payload.uid);
  if(col){
    yield put({type:actions.CREDENCIAL_LOAD_SUCCESS, payload:{col:true, admin:col.diretor, gerente:col.gerente}})
  }else{
    yield put({type:actions.CREDENCIAL_LOAD_SUCCESS, payload:{col:false, admin:false,gerente:false}})
  }
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}


export function* emailVerificationSaga(actionCodeSettings) {
  try {
    yield call(rsf.auth.sendEmailVerification);
    console.log('e-mail enviado com sucesso!')
  }
  catch(error) {
    console.log('erro ao tentar enviar e-mail',error)
  }
}

export function* emailReset(email, actionCodeSettings) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, email.email);
    yield put({type:actions.EMAIL_RESET_OK})
  }
  catch(error) {
    yield put({type:actions.EMAIL_RESET_ER})
    console.log(error)
    
  }
}

function* updateProfileSaga(name,photo) {
  try {
    yield call(rsf.auth.updateProfile, {
      displayName: name,
      photoURL: photo
    });
  }
  catch(error) {
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREDENCIAL_LOAD,loadCredencial),
  ]);
}
