import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import Usuario from '../../customApp/model/Usuario';
import FirebaseHelper from '../../helpers/firebase';

const {rsf} = FirebaseHelper;


export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    //const { token } = payload;
    if (payload) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload,
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });

    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.ra);
    
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    try {
      const data = yield call(rsf.auth.signOut);
      yield call({type:'CREDENCIAL_RESET'});
      yield put(push('/'));
    }
    catch(error) {
      console.log(error)
    }
    
  });
}

export function* getUser() {
  yield takeEvery(actions.GET_USER, function*(){
    Usuario.getUsers(us=>{
     
    });
    
  });
}

export function* checkAuthorization() {
  try {
      // events on this channel fire when the user logs in or logs out
      const channel = yield call(rsf.auth.channel)

      while (true) {
        const { user } = yield take(channel)

        if (user){
          yield put({type: actions.LOGIN_SUCCESS,
            payload: user,
           })
          yield put ({type:'CREDENCIAL_LOAD',uid:user.uid})
        }else{
        }
      }
  }catch (error) {
    console.log(error);
  }
} 


export function* emailVerificationSaga(actionCodeSettings) {
  try {
    yield call(rsf.auth.sendEmailVerification);
    console.log('e-mail enviado com sucesso!')
  }
  catch(error) {
    console.log('erro ao tentar enviar e-mail',error)
  }
}

export function* emailReset(email, actionCodeSettings) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, email.email);
    yield put({type:actions.EMAIL_RESET_OK})
  }
  catch(error) {
    yield put({type:actions.EMAIL_RESET_ER})
    console.log(error)
    
  }
}

function* createUser(payload) {
  console.log(payload.email,payload.password,payload.name)
  try {
    const user = yield call(rsf.auth.createUserWithEmailAndPassword, payload.email, payload.password);
    yield updateProfileSaga(payload.name);
    yield put({type:'CREATE_USER_SUCCESS',name:payload.name, email:payload.email});
    yield put(loginSuccess(user));
  }
  catch(error) {
    yield put({type:actions.CREATE_USER_ERROR,payload:error});
  }
}

function* updateProfileSaga(name,photo) {
  try {
    yield call(rsf.auth.updateProfile, {
      displayName: name,
      photoURL: photo
    });
  }
  catch(error) {
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_AUTHORIZATION,checkAuthorization),
    takeEvery(actions.EMAIL_RESET,emailReset),
    takeEvery(actions.CREATE_USER,createUser),
    takeEvery(actions.EMAIL_VERIFICATION_SAGA,emailVerificationSaga),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(getUser),
  ]);
}
