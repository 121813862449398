export default {
  apiUrl: 'https://app.escrisp.com.br/'
};
const siteConfig = {
  siteName: 'Escritório São Paulo',
  siteNameMini: 'Escritório SP',
  siteIcon: 'ion-flash',
  linkIcon: './image/favicon.png',
  footerText: 'Escritório São Paulo ©2020 Criado por NFJ Sistemas'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'brazil';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};
const Auth0Config = {
  domain: 'app.escrisp.com.br',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'brazil',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'http://localhost:3000/auth0loginCallback'
    },
    languageDictionary: {
      title: 'Escritório São Paulo',
      emailInputPlaceholder: 'email@email.com.br',
      passwordInputPlaceholder: 'sua senha'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#E14615',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};
const firebaseConfig = {
  apiKey: 'AIzaSyCg83iu_wggIL414Yq7kb3fJjUdVYNnBcI',
  authDomain: 'teste-2d522.web.app',
  databaseURL: 'https://teste-2d522.firebaseio.com',
  projectId: 'teste-2d522',
  storageBucket: 'gs://teste-2d522.appspot.com',
  messagingSenderId: '202295869274',
  appId: "1:202295869274:web:8f8394e419b1db0c",
  appName: "app.escrisp.com.br"
};
const googleConfig = {
  apiKey: '202295869274-oml9mkv4l2i7h80tcsfi8449pqafn6s8.apps.googleusercontent.com' //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};
const youtubeSearchApi = 'AIzaSyCg83iu_wggIL414Yq7kb3fJjUdVYNnBcI';
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi
};
