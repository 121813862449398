import Auth from './auth/reducer';
import App from './app/reducer';
import Credencial from './credencial/reducer';
import Box from './box/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import YoutubeSearch from './youtubeSearch/reducers';
import DevReducers from '../customApp/redux/reducers';
import Covid from './covid/reducers';
import modal from './modal/reducer';
import Assessor from './assessor/reducer';
import UploadArq from './uploadArq/reducers';

export default {
  Auth,
  App,
  Credencial,
  ThemeSwitcher,
  LanguageSwitcher,
  Box,
  YoutubeSearch,
  Covid,
  modal,
  Assessor,
  UploadArq,
  ...DevReducers,
};
