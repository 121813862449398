import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import {apiRespEscrita} from '../../apiFirebase/apiEscritaListaResp';
const {rsf} = FirebaseHelper;

export function* loadAssessorPj(payload) {
  let data = [];
  let r = yield call(rsf.database.read, 'assessorpj/'+payload.payload.uid);
  if(r){

    Object.keys(r).map((key, index) => {
      let emp = call(rsf.database.read, 'empresas/'+key);
      if(emp){
        data.push(emp);
      }
      
    });
    yield put({type:actions.ASSESSOR_PJ_LOAD_SUCCESS,payload:{'data':data}})
  }else{
    yield put({type:actions.ASSESSOR_PJ_LOAD_ERROR})
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ASSESSOR_PJ_LOAD,loadAssessorPj),
  ]);
}
