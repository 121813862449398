import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  covides: {},
  modalActive: false,
  covid: {
    key: null,
    id: new Date().getTime(),
    title: '',
    slug: '',
    excerpt: '',
    status: 'draft', // publish
    description: '',
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_DATABASE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_DATABASE_SUCCESS:
      let dt;
      if(payload.data){
        dt = payload.data;
      }else{
        dt = {}
      }
      return {
        ...state,
        isLoading: false,
        covides: dt,
        errorMessage: false,
      };
    case actions.LOAD_FROM_DATABASE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Houve um problema',
      };
    case actions.TOGGLE_DATABASE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        covid: payload.data == null ? initState.covid : payload.data,
      };
    case actions.DATABASE_UPDATE:
      return {
        ...state,
        covid: payload.data,
      };
    default:
      return state;
  }
}
