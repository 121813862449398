import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
//import img from {siteConfig.linkIcon};
import img from '../../image/favicon.png';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <i><img src={img} /></i>
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard">{siteConfig.siteNameMini}</Link>
        </h3>
      )}
    </div>
  );
};
