import antdSA from 'antd/lib/locale-provider/pt_BR';
import appLocaleData from 'react-intl/locale-data/br';
import brMessages from '../locales/pt_BR.json';

const brLang = {
  messages: {
    ...brMessages
  },
  antd: antdSA,
  locale: 'br',
  data: appLocaleData
};
export default brLang;
