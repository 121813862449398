import actions from "./actions";

const initState = { load:false, admin: false, col:false, gerente:false, loadErro:false, loadSuccess:false, msg:""};

export default function credencialReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREDENCIAL_LOAD_SUCCESS:
      return {
        ...state,
        load: true,
        admin: action.payload.admin,
        col: action.payload.col,
        gerente: action.payload.gerente,
        loadSuccess: true,
      };
   case actions.CREDENCIAL_RESET:
      return initState;
   case actions.CREDENCIAL_LOAD_ERROR:
        return {...state, 
          loadErro:true,
          msg:action.payload.msg
        };  
    default:
      return {...state};
  }
}
