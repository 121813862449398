import { all, takeEvery, put, call} from 'redux-saga/effects';
import { eventChannel } from "redux-saga";
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';
import axios from 'axios'
import fileDownload from 'js-file-download'
import notifications from '@iso/components/Feedback/Notification';

const COLLECTION_NAME = 'upload_site'; // change your collection

function* loadFromFirestore({ payload }) {
  try {
    let snapshots;
    let lidos=[];
    let nao_lidos=[];

    snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/nao_lidos/"+payload.uid);
    snapshots.forEach(dt => {
        nao_lidos = {
          ...nao_lidos,
          [dt.id]: {...dt.data(),'key':dt.id}
        }
    });

    snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/lidos/"+payload.uid);
    snapshots.forEach(dt => {
      lidos = {
        ...lidos,
        [dt.id]: dt.data()
      }
      
  });
   
    yield put(actions.loadFromFireStoreSuccess(lidos,nao_lidos));
    
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadFromFirestoreGerencia({ payload }) {
  try {
    let snapshots;
    let lidos=[];
    let nao_lidos=[];

    snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/nao_lidos/"+payload.uid);
    snapshots.forEach(dt => {
        nao_lidos = {
          ...nao_lidos,
          [dt.id]: {...dt.data(),'key':dt.id}
        }
    });

    snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/lidos/"+payload.uid);
    snapshots.forEach(dt => {
      lidos = {
        ...lidos,
        [dt.id]: dt.data()
      }
      
  });
   
    yield put(actions.loadFromFireStoreGerenciaSuccess(lidos,nao_lidos));
    
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreGerenciaError(error));
  }
}

function* loadAnexos({ payload }) {
  try {
    const snapshots = yield call(rsf.firestore.getCollection, COLLECTION_NAME+"/"+payload.uid+"/cursos/"+payload.key+"/arquivos/");
    let data;
    let anexos={};
    snapshots.forEach(dt => {
        data = {
          ...data,
          [dt.id]: dt.data()
        }
    });
    anexos[payload.key]=data;
    yield put(actions.loadAnexosSuccess(anexos));
  } catch (error) {
    console.log(error)
    yield put(actions.loadAnexosError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName, uid } = payload;
  const fdata = {...omit(data, ['file'])}
  try {
    // yield call(uploadFile,'private/curso/'+data.email,data.file)
    switch (actionName) {
      case 'delete':
        // yield call(
        //   rsf.firestore.deleteDocument,
        //   `${COLLECTION_NAME}/${data.key}`);
        break;
      case 'update':
        // yield call(
        //   rsf.firestore.setDocument,
        //   `${COLLECTION_NAME}/${payload.email}/cursos/${data.key}`,
        //   {
        //     ...omit(fdata, ['key']),
        //   }
        // );
        // if(data.file){
        //   yield call(uploadFiles,'cqc/cursos/'+payload.email+'/'+data.key+"/",data.file,data.key, payload.email)
        //   yield put({ type: actions.LOAD_ANEXOS, payload:{email:payload.email, key:data.key}});
        // }
        // if(data.published){
        //   yield call(rsf.firestore.setDocument,`cursos/${data.key}`,{...fdata, email:payload.email, key:data.key},{merge:true})
        // }
        break;
      default:
        
        if(data.file){
          let anexo='';
            data.file.map(f=>{
              anexo+=" ->"+f.name+"; ";
            })
          const key = yield call(rsf.firestore.addDocument, `${COLLECTION_NAME}/nao_lidos/${uid}`, {...fdata, anexo});


          yield call(uploadFiles,'upload/site/'+payload.uid+'/'+key.id+"/",data.file,key.id, uid)
          yield put({ type: actions.LOAD_ANEXOS, payload:{uid, key:key.id}});
        }else{
          yield call(notifications['error'],{
            message: 'Erro',
            description:
              'Não há arquivo anexado!',
          });
        }
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload:{uid:payload.uid}});
    yield put(actions.resetFireStoreDocuments());
    yield call(notifications['success'],{
      message: 'Upload efetuado com Sucesso!',
      description:
        'Recebemos o(s) arquivo(s)!!',
    });
  } catch (error) {

    yield call(notifications['error'],{
      message: 'Falha',
      description:
        'Houve um problema, nos informe para que consigamos atualizar o nosso sistema!',
    });
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* uploadFiles(path, file,key,uid) {
  if(file.length >0){
    yield all(file.map(f=>{
      return call(uploadFile,path+f.name,f,key,f.name,uid)
    }))
  }
}

function* uploadFile(path, file,key,fileName,uid) {
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield task
  yield call(updatePathUpload,path,key,fileName,uid)
}

function* updatePathUpload(path,key,fileName,uid){
  yield call(
    rsf.firestore.addDocument,
    `${COLLECTION_NAME}/nao_lidos/${uid}/${key}/anexos`,
    {
      "caminho":path,
      "fileName":fileName
    }
  );
}

function* downloadFile(data) {
  try{
    const url = yield call(rsf.storage.getDownloadURL, data.payload.caminho);
    
    yield call(handleDownload, url,data.payload.fileName);
  }catch(erro){
    console.log(erro)
  }
  
}

function* handleDownload(url, filename){
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
}

function* deleteAnexo({payload}) {
  try{
    if(payload.path && payload.keyAnexo){
      yield call(rsf.firestore.deleteDocument, COLLECTION_NAME+"/"+payload.path+payload.keyAnexo);
      let s = payload.path.split("/");
      yield put({type: actions.LOAD_ANEXOS, payload:{email:s[0],key:s[2]}});
    }
  }catch(erro){
    console.log(erro)
  }
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
    takeEvery(actions.LOAD_FROM_FIRESTORE_GERENCIA, loadFromFirestoreGerencia),
    takeEvery(actions.DOWNLOAD, downloadFile),
    takeEvery(actions.LOAD_ANEXOS, loadAnexos),
    takeEvery(actions.DELETE_ANEXO, deleteAnexo),
  ]);
}
