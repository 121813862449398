import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingGerencia: false,
  errorMessage: false,
  fdatas: {},
  nao_lidos: {},
  lidos:{},
  nao_lidos_gerencia: {},
  lidos_gerencia:{},
  anexos:{},
  modalActive: false,
  showButton:true,
  showMsg:false,
  msg:'',
  msgType:'default',
  fdata: {
    descricao:'',
    file:[]
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,

      };
      case actions.LOAD_FROM_FIRESTORE_GERENCIA:
      return {
        ...state,
        isLoadingGerencia: true,
        errorMessage: false,
        modalActive: false,

      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lidos:payload.lidos,
        nao_lidos:payload.nao_lidos,
        errorMessage: false,
        showButton:true,
      };
    case actions.LOAD_FROM_FIRESTORE_GERENCIA_SUCCESS:
      return {
        ...state,
        isLoadingGerencia: false,
        lidos_gerencia:payload.lidos_gerencia,
        nao_lidos_gerencia:payload.nao_lidos_gerencia,
        errorMessage: false,
        showButton:true,
      };  
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.error,
        msg: 'Houve um problema no carregamento',
        showButton:true,
      };
      case actions.LOAD_FROM_FIRESTORE_GERENCIA_ERROR:
        return {
          ...state,
          isLoadingGerencia: false,
          errorMessage: payload.error,
          msg: 'Houve um problema no carregamento',
          showButton:true,
        };
      case actions.LOAD_ANEXOS:
        return {
          ...state,
          isLoading: true,
          errorMessage: false,
        };
      case actions.LOAD_ANEXOS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          anexos: payload.data,
          errorMessage: false,
        };
      case actions.LOAD_ANEXOS_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: 'Houve um problema no carregamento',
        };  
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        fdata: payload.data == null ? initState.fdata : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        fdata: payload.data,
      };
    case actions.SAVE_INTO_FIRESTORE:
      return {
        ...state,
        showButton: false,
      };  

    case actions.RESET_FIRESTORE_DOCUMENTS:
      return {
        ...state,
        fdata: {
          descricao:'',
          file:[]
        },
      };   

      case actions.SHOW_MENSAGEM:
        return {
          ...state,
          showMsg:true,
          msg:payload.msg,

        };   
    default:
      return state;
  }
}
