import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import credencialSagas from './credencial/saga';
import youtubeSearchSagas from './youtubeSearch/sagas';
import devSagas from '../customApp/redux/sagas';
import covid from './covid/sagas';
import assessorSagas from './assessor/saga';
import uploadArq from './uploadArq/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    credencialSagas(),
    youtubeSearchSagas(),
    devSagas(),
    covid(),
    assessorSagas(),
    uploadArq()
  ]);
}
